import { render, staticRenderFns } from "./carInfo.vue?vue&type=template&id=2f80b072&scoped=true"
import script from "./carInfo.vue?vue&type=script&lang=js"
export * from "./carInfo.vue?vue&type=script&lang=js"
import style0 from "./carInfo.vue?vue&type=style&index=0&id=2f80b072&prod&lang=scss"
import style1 from "./carInfo.vue?vue&type=style&index=1&id=2f80b072&prod&lang=scss&scoped=true"
import style2 from "./carInfo.vue?vue&type=style&index=2&id=2f80b072&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f80b072",
  null
  
)

export default component.exports